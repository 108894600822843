<template>
  <div class="scheme">
    <el-descriptions title="订单信息" :column="1" border>
      <el-descriptions-item label="订单编号">{{ orderInfo.salesOrderNo }}</el-descriptions-item>
      <el-descriptions-item label="品牌">{{ orderInfo.brand }}</el-descriptions-item>
      <el-descriptions-item label="主机电压">{{ orderInfo.voltage }}</el-descriptions-item>
      <el-descriptions-item label="主机电流">{{ orderInfo.electric }}</el-descriptions-item>
      <el-descriptions-item label="有/无机房">{{ orderInfo.motorRoom }}</el-descriptions-item>
      <el-descriptions-item label="层站">{{ orderInfo.loading }}</el-descriptions-item>
      <el-descriptions-item label="联系方式">
        <a :href="`tel:${orderInfo.mobile}`">{{ orderInfo.mobile }}</a>
      </el-descriptions-item>
    </el-descriptions>

    <div style="border: 1px solid #e5e5e5;margin-top: 10px;">
      <div class="configurationItem" style="font-weight: 700;">
        <div class="configurationItemName">类型</div>
        <div class="configurationItemValue">
          <div class="valueItem">
            <div class="cell name" style="height: 40px;">名称</div>
            <div class="cell spec" style="height: 40px;">规格</div>
            <div class="cell count" style="height: 40px;">数量</div>
            <div class="cell unit" style="height: 40px;">单位</div>
          </div>
        </div>
      </div>
      <div class="configurationItem" v-for="(value, key) in inventory" :key="key">
        <div class="configurationItemName">
          <div v-if="key == 10">
            <div v-for="(item, index) in '控制柜'" :key="index">{{ item }}</div>
          </div>
          <div v-else-if="key == 20">
            <div v-for="(item, index) in '线系统'" :key="index">{{ item }}</div>
          </div>
          <div v-else-if="key == 30">
            <div v-for="(item, index) in '井道部件'" :key="index">{{ item }}</div>
          </div>
          <div v-else-if="key == 40">
            <div v-for="(item, index) in '人机界面'" :key="index">{{ item }}</div>
          </div>
        </div>
        <div class="configurationItemValue">
          <div class="valueItem" v-for="(item, index) in value" :key="index">
            <div class="cell name" style="justify-content: flex-start;">{{ item.materialName }}</div>
            <div class="cell spec" style="justify-content: flex-start;">{{ item.specifications }}</div>
            <div class="cell count">{{ parseFloat(item.quantity) }}</div>
            <div class="cell unit">{{ item.unitName }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      inventory: [],
      orderInfo: {
        salesOrderNo: "",
        brand: "",
        voltage: "",
        electric: "",
        motorRoom: "",
        loading: "",
        mobile: "",
      },
    };
  },

  mounted() {
    this.getById(this.$route.params.id);
  },

  methods: {
    getById(id) {
      this.$http.get(`mODSalesOrders/${id}`).then(res => {
        this.orderInfo = res.data;
        this.orderInfo.brand = res.data.modSalesOrderProperties.find(item => item.propertyName === "品牌").propertyValue;
        this.orderInfo.voltage = res.data.modSalesOrderProperties.find(item => item.propertyName === "主机电压").propertyValue;
        this.orderInfo.electric = res.data.modSalesOrderProperties.find(item => item.propertyName === "主机电流").propertyValue;
        this.orderInfo.motorRoom = res.data.modSalesOrderProperties.find(item => item.propertyName === "有/无机房").propertyValue;
        this.orderInfo.loading = res.data.modSalesOrderProperties.find(item => item.propertyName === "层站").propertyValue;
        this.inventory = this.groupBy(res.data.modSalesOrderMaterials, "materialGroupId");
      });
    },

    groupBy(array, key) {
      return array.reduce((result, currentItem) => {
        const groupKey = key instanceof Function ? key(currentItem) : currentItem[key];
        if (!result[groupKey]) {
          result[groupKey] = [];
        }
        result[groupKey].push(currentItem);
        return result;
      }, {});
    }
  }
};
</script>

<style lang="scss" scoped>
.scheme {
  padding: 10px 15px;

  .configurationItem {
    display: flex;
    align-items: center;
    font-size: 12px;
    border-bottom: 1px solid #e5e5e5;

    &:last-child {
      border-bottom: none;
    }

    .configurationItemName {
      width: 30px;
      text-align: center;
    }

    .configurationItemValue {
      flex: 1;
      overflow: hidden;

      .valueItem {
        display: flex;
        align-items: stretch;
        border-bottom: 1px solid #e5e5e5;

        &:last-child {
          border-bottom: none;
        }

        .cell {
          padding: 5px;
          box-sizing: border-box;
          border-left: 1px solid #e5e5e5;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .spec {
          flex: 1;
          overflow: hidden;
        }

        .name {
          width: 100px;
        }

        .unit,
        .count,
        .note {
          width: 35px;
          text-align: center;
        }

        .materialNo {
          width: 45px;
        }
      }
    }
  }
}
</style>